import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFruitslist } from './store/index';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const languages = ['English', 'Tamil', 'Hindi', 'Telugu', 'Malayalam', 'Kannada'];

const AddFruits = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('English');
  const [newFruits, setnewFruits] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = { name: '', content: '' };
      return acc;
    }, {})
  );
  const [imageData, setImageData] = useState(null);

  // Handle Tab Change
  const handleTabChange = (lang) => setActiveTab(lang);

  // Handle Input Changes for each language
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewFruits((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  // Handle Image File Change
  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const modifiedFileName = file.name.replace(/\s+/g, '_');
      const modifiedFile = new File([file], modifiedFileName, { type: file.type });
      setImageData(modifiedFile);
    }
  };

  // Save Data
  const handleAddFruits = async () => {
    const completeFruitsData = languages.reduce((acc, lang) => {
      acc[lang] = newFruits[lang] || { name: '', content: '' };
      return acc;
    }, {});

      const newEntry = {
    ...completeFruitsData,
    fruitId: uuidv4(),
  };

    const formData = new FormData();
    formData.append('img', imageData);
    formData.append('data', JSON.stringify({ newEntry }));

    try {
      // Dispatch the form data directly without wrapping it in another object
      await dispatch(addFruitslist(formData)).then((response) => {
        if (response.payload.message === 'Data stored successfully') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Fruits details have been added successfully.'
          });
          navigate("/fruits")
        }
        // Reset the state after submission
        setnewFruits(
          languages.reduce((acc, lang) => {
            acc[lang] = { name: '', content: '' };
            return acc;
          }, {})
        );
        setImageData(null);
      })
    } catch (error) {
      console.error('Failed to add Fruits entry:', error);
    }
  };

  const isFormValid = newFruits[activeTab].name && newFruits[activeTab].content && imageData;

  return (
    <div className='icon_backButton'>
      <Link to={"/fruits"}>
        <i className="fas fa-arrow-alt-circle-left"></i>
      </Link>
      <div className="containers">
        <h2>Add Fruits List</h2>
        <div className="tabers">
          {languages.map((lang) => (
            <button
              key={lang}
              onClick={() => handleTabChange(lang)}
              className="tabers"
              style={{
                backgroundColor: activeTab === lang ? '#4CAF50' : '#ccc',
              }}
            >
              {lang}
            </button>
          ))}
        </div>
        <div className="commonSection">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="fileInputs"
          />
          {imageData && (
            <img
              src={URL.createObjectURL(imageData)}
              alt="Preview"
              className="imagePreview"
            />
          )}
        </div>
        <div className="formContainers">
          <h3>{activeTab} Details</h3>
          <input
            type="text"
            name="name"
            className="inputs"
            value={newFruits[activeTab]?.name || ''}
            onChange={handleInputChange}
            placeholder="Enter Fruits Name"
          />
          <textarea
            name="content"
            className="textareas"
            value={newFruits[activeTab]?.content || ''}
            onChange={handleInputChange}
            style={{height:"100px"}}
            placeholder="Enter Fruits Content (HTML Format)"
          />
        </div>
        <button
          className="saveButton"
          onClick={handleAddFruits}
          disabled={!isFormValid}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddFruits;
