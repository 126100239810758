import webservice from '../../../../services/webservice';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('Medical/getdata', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getMedicalChecklist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const MedicalcheckupList = createAsyncThunk('Medical/MedicalcheckupList', async (data) => {
    try {
        const response = await fetch(webservice + 'api/CheckUplist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addCheckUplist = createAsyncThunk('Medical/addCheckUplist', async (data) => {
    try {
        const response = await fetch(webservice + 'api/addCheckUplist', {
            method: 'POST',
            body: JSON.stringify({ data }), 
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateCheckUplist = createAsyncThunk('Medical/updateCheckUplist', async (data) => {
    try {
        const response = await fetch(webservice + 'api/updateMedicalChecklist', {
            method: 'POST',
            body: JSON.stringify({ data }), 
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteCheckupdetails = createAsyncThunk('Medical/deleteMedicalCheckupList', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteMedicalCheckupList', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});
