import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addSymptomslist } from './store/index';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const languages = ['English', 'Tamil', 'Hindi', 'Telugu', 'Malayalam', 'Kannada'];

const AddSymptom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('English');
  const [newSymptom, setNewSymptom] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = { name: '', content: '', reason: '' };
      return acc;
    }, {})
  );
  // const [imageData, setImageData] = useState(null);

  // Handle Tab Change
  const handleTabChange = (lang) => setActiveTab(lang);

  // Handle Input Changes for each language
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSymptom((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  // Handle Image File Change
  // const handleFileChange = (e) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const modifiedFileName = file.name.replace(/\s+/g, '_');
  //     const modifiedFile = new File([file], modifiedFileName, { type: file.type });
  //     setImageData(modifiedFile);
  //   }
  // };

  // Save Data
  const handleAddSymptom = async () => {

    const completeSymptomData = languages.reduce((acc, lang) => {
      acc[lang] = newSymptom[lang] || { name: '', content: '', reason: '' };
      return acc;
    }, {});

      const data = {
    ...completeSymptomData,
    symptomId: uuidv4(),
  };

    // const formData = new FormData();
    // formData.append('img', imageData);
    // formData.append('data', JSON.stringify({ newEntry }));

    try {
      // Dispatch the form data directly without wrapping it in another object
      await dispatch(addSymptomslist(data)).then((response) => {
        if (response.payload.message === 'Data stored successfully') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Symptom details have been added successfully.'
          });
          navigate("/symptoms")
        }
        // Reset the state after submission
        setNewSymptom(
          languages.reduce((acc, lang) => {
            acc[lang] = { name: '', content: '', reason: '' };
            return acc;
          }, {})
        );
        // setImageData(null);
      })
    } catch (error) {
      console.error('Failed to add Symptom entry:', error);
    }
  };

  // const isFormValid = newSymptom[activeTab].name && newSymptom[activeTab].content && newSymptom[activeTab].reason && imageData;
  const isFormValid = newSymptom[activeTab].name && newSymptom[activeTab].reason && newSymptom[activeTab].content;

  return (
    <div className='icon_backButton'>
      <Link to={"/symptoms"}>
        <i className="fas fa-arrow-alt-circle-left"></i>
      </Link>
      <div className="containers">
        <h2>Add Symptom List</h2>
        <div className="tabers">
          {languages.map((lang) => (
            <button
              key={lang}
              onClick={() => handleTabChange(lang)}
              className="tabers"
              style={{
                backgroundColor: activeTab === lang ? '#4CAF50' : '#ccc',
              }}
            >
              {lang}
            </button>
          ))}
        </div>
        {/* <div className="commonSection">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="fileInputs"
          />
          {imageData && (
            <img
              src={URL.createObjectURL(imageData)}
              alt="Preview"
              className="imagePreview"
            />
          )}
        </div> */}
        <div className="formContainers">
          <h3>{activeTab} Details</h3>
          <input
            type="text"
            name="name"
            className="inputs"
            value={newSymptom[activeTab]?.name || ''}
            onChange={handleInputChange}
            placeholder="Enter Symptom Name"
          />
          <textarea
            name="reason"
            className="textareas"
            value={newSymptom[activeTab]?.reason || ''}
            onChange={handleInputChange}
            style={{height:"70px"}}
            placeholder="Enter Symptom reason (HTML Format)"
          />
          <textarea
            name="content"
            className="textareas"
            value={newSymptom[activeTab]?.content || ''}
            onChange={handleInputChange}
            style={{height:"100px"}}
            placeholder="Enter Symptom Content (HTML Format)"
          />
        </div>
        <button
          className="saveButton"
          onClick={handleAddSymptom}
          disabled={!isFormValid}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddSymptom;
