import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSymptomsdetails, Symptomsdetails } from './store/index';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const languages = ['English', 'Tamil', 'Hindi', 'Telugu', 'Malayalam', 'Kannada'];

const UpdateSymptom = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('English');
  // const [imageData, setImageData] = useState(null);
  const [newSymptom, setNewSymptom] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = { name: '', content: '' , reason: '' };
      return acc;
    }, {})
  );  

  // Fetch Data and Populate State
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(Symptomsdetails(id));
        const fetchedData = response.payload.data;

        // Populate `newSymptom` state
        const updatedFirstAid = languages.reduce((acc, lang) => {
          const langData = fetchedData.find((item) => item.collectionName.includes(lang.toLowerCase()));
          acc[lang] = {
            name: langData?.data?.name || '',
            content: langData?.data?.content || '',
            reason: langData?.data?.reason || '',
          };
          return acc;
        }, {});
        setNewSymptom(updatedFirstAid);
        // Populate `imageData`
        // if (fetchedData[0]?.data?.img) {
        //   setImageData(fetchedData[0].data.img);
        // }
      } catch (error) {
        console.error('Error fetching first aid details:', error);
      }
    };
    fetchData();
  }, [dispatch, id]);

  // Handle Tab Change
  const handleTabChange = (lang) => setActiveTab(lang);

  // Handle Input Changes for each language
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSymptom((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  // Handle Image File Change
  // const handleFileChange = (e) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const modifiedFileName = file.name.replace(/\s+/g, '_');
  //     const modifiedFile = new File([file], modifiedFileName, { type: file.type });
  //     setImageData(modifiedFile);
  //   }
  // };

  // Update Data
  const handleUpdateSymptom = async () => {
    const data = {
      ...newSymptom,
      symptomId: id, // Use the existing ID
    };    

    // const formData = new FormData();
    // if (imageData instanceof File) {
    //   formData.append('img', imageData); // Append new file if updated
    // }
    // formData.append('data', JSON.stringify(updatedEntry));

    try {
      const response = await dispatch(updateSymptomsdetails(data));
      if (response.payload.message === 'Data updated successfully') {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'First Aid details have been updated successfully.',
        });
        navigate("/symptoms")
      }
    } catch (error) {
      console.error('Failed to update first aid entry:', error);
    }
  };

  // const isFormValid = newSymptom[activeTab].name && newSymptom[activeTab].content && imageData;
  const isFormValid = newSymptom[activeTab].name && newSymptom[activeTab].content && newSymptom[activeTab].reason;

  return (
    <div className='icon_backButton'>
      <Link to="/symptoms">
        <i className="fas fa-arrow-alt-circle-left"></i>
      </Link>
      <div className="containers">
        <h2>Edit Symptom Details</h2>
        <div className="tabers">
          {languages.map((lang) => (
            <button
              key={lang}
              onClick={() => handleTabChange(lang)}
              className="tabers"
              style={{
                backgroundColor: activeTab === lang ? '#4CAF50' : '#ccc',
              }}
            >
              {lang}
            </button>
          ))}
        </div>
        {/* <div className="commonSection">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="fileInputs"
          />
          {imageData && (
            <img
              src={imageData instanceof File ? URL.createObjectURL(imageData) : imageData}
              alt="Preview"
              className="imagePreview"
            />
          )}
        </div> */}
        <div className="formContainers">
          <h3>{activeTab} Details</h3>
          <input
            type="text"
            name="name"
            className="inputs"
            value={newSymptom[activeTab]?.name || ''}
            onChange={handleInputChange}
            placeholder="Enter Symptom Name"
          />
          <textarea
            name="reason"
            className="textareas"
            value={newSymptom[activeTab]?.reason || ''}
            onChange={handleInputChange}
            style={{height:"70px"}}
            placeholder="Enter Symptom reason (HTML Format)"
          />
          <textarea
            name="content"
            className="textareas"
            value={newSymptom[activeTab]?.content || ''}
            onChange={handleInputChange}
            style={{height:"100px"}}
            placeholder="Enter Symptom Content (HTML Format)"
          />
        </div>
        <button
          className="saveButton"
          onClick={handleUpdateSymptom}
          disabled={!isFormValid}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateSymptom;
