import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('Vaccine/getVaccinelist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getVaccinelist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const VaccinationList = createAsyncThunk('Vaccine/VaccinationList', async (data) => {
    try {
        const response = await fetch(webservice + 'api/Vaccinelist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addVaccine = createAsyncThunk('Vaccine/addVaccine', async (data) => {
    try {
        const response = await fetch(webservice + 'api/addVaccinelist', {
            method: 'POST',
            body: JSON.stringify({ data }), 
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateVaccine = createAsyncThunk('Vaccine/updateVaccine', async (data) => {
    try {
        const response = await fetch(webservice + 'api/updateVaccinelist', {
            method: 'POST',
            body: JSON.stringify({ data }), 
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteVaccinedetails = createAsyncThunk('Vaccine/deleteVaccinedetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteVaccinedetails', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});