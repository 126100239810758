import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('Vegetables/getVegetableslist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getVegetablelist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const Vegetablesdetails = createAsyncThunk('Vegetables/Vegetablesdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/vegetabledetails', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addVegetableslist = createAsyncThunk('Vegetables/addVegetableslist', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/addvegetablelist', {
            method: 'POST',
            body: formData, 
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateVegetablesdetails = createAsyncThunk('Vegetables/updateVegetablesdetails', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/updatevegetabledetails', {
            method: 'POST',
            body: formData, 
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteVegetablesdetails = createAsyncThunk('Vegetables/deleteVegetablesdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deletevegetablelist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});