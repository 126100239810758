import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateVaccine, VaccinationList } from './store/index';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const languages = ['English', 'Tamil', 'Hindi', 'Telugu', 'Malayalam', 'Kannada'];

const UpdateVaccinelist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('English');
  const [newVaccine, setNewVaccine] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = { name: '', content: '',reason: '', daysStart: '', daysEnd: '' };
      return acc;
    }, {})
  );  

  // Fetch Data and Populate State
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(VaccinationList(id));
        const fetchedData = response.payload.data;

        // Populate `newVaccine` state
        const updatedFirstAid = languages.reduce((acc, lang) => {
          const langData = fetchedData.find((item) => item.collectionName.includes(lang.toLowerCase()));
          acc[lang] = {
            name: langData?.data?.name || '',
            content: langData?.data?.content || '',
            reason: langData?.data?.reason || '',
            daysStart: langData?.data?.daysStart || '',
            daysEnd: langData?.data?.daysEnd || '',
          };
          return acc;
        }, {});
        setNewVaccine(updatedFirstAid);
      } catch (error) {
        console.error('Error fetching Checkup details:', error);
      }
    };
    fetchData();
  }, [dispatch, id]);

  // Handle Tab Change
  const handleTabChange = (lang) => setActiveTab(lang);

  // Handle Input Changes for each language
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVaccine((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  // Update Data
  const handleUpdateVaccinelist = async () => {
    const data = {
      ...newVaccine,
      vaccineId: id, // Use the existing ID
    };    

    try {
      const response = await dispatch(updateVaccine(data));
      if (response.payload.message === 'Data updated successfully') {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Vaccine details have been updated successfully.',
        });
        navigate("/vaccination")
      }
    } catch (error) {
      console.error('Failed to update first aid entry:', error);
    }
  };

  const isFormValid = newVaccine[activeTab].name && newVaccine[activeTab].content;

  return (
    <div className='icon_backButton'>
      <Link to="/vaccination">
        <i className="fas fa-arrow-alt-circle-left"></i>
      </Link>
      <div className="containers">
        <h2>Edit Vaccine Details</h2>
        <div className="tabers">
          {languages.map((lang) => (
            <button
              key={lang}
              onClick={() => handleTabChange(lang)}
              className="tabers"
              style={{
                backgroundColor: activeTab === lang ? '#4CAF50' : '#ccc',
              }}
            >
              {lang}
            </button>
          ))}
        </div>
        <div className="formContainers">
          <h3>{activeTab} Details</h3>
          <input
            type="text"
            name="name"
            className="inputs"
            value={newVaccine[activeTab]?.name || ''}
            onChange={handleInputChange}
            placeholder="Enter Vaccine Name"
          />
          <textarea
            name="content"
            className="textareas"
            value={newVaccine[activeTab]?.content || ''}
            onChange={handleInputChange}
            style={{height:"100px"}}
            placeholder="Enter Vaccine Content (HTML Format)"
          />
          <input
            type="text"
            name="reason"
            className="inputs"
            value={newVaccine[activeTab]?.reason || ''}
            onChange={handleInputChange}
            placeholder="Enter Vaccine reason"
          />
          <input
            type="text"
            name="daysStart"
            className="inputs"
            value={newVaccine[activeTab]?.daysStart || ''}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleInputChange(e);
              }
            }}
            placeholder="Enter Days Start"
          />
          <input
              type="number"
              name="daysEnd"
              className="inputs"
              value={newVaccine[activeTab]?.daysEnd || ''}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleInputChange(e);
                }
              }}
              placeholder="Enter Days End"
            />
        </div>
        <button
          className="saveButton"
          onClick={handleUpdateVaccinelist}
          disabled={!isFormValid}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateVaccinelist;
