import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateGrainsdetails, Grainsdetails } from './store/index';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const languages = ['English', 'Tamil', 'Hindi', 'Telugu', 'Malayalam', 'Kannada'];

const UpdateFirstAid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('English');
  const [imageData, setImageData] = useState(null);
  const [newgrains, setNewgrains] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = { name: '', content: '' };
      return acc;
    }, {})
  );  

  // Fetch Data and Populate State
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(Grainsdetails(id));
        const fetchedData = response.payload.data;

        // Populate `newgrains` state
        const updatedgrains = languages.reduce((acc, lang) => {
          const langData = fetchedData.find((item) => item.collectionName.includes(lang.toLowerCase()));
          acc[lang] = {
            name: langData?.data?.name || '',
            content: langData?.data?.content || '',
          };
          return acc;
        }, {});
        setNewgrains(updatedgrains);
        // Populate `imageData`
        if (fetchedData[0]?.data?.img) {
          setImageData(fetchedData[0].data.img);
        }
      } catch (error) {
        console.error('Error fetching Grains details:', error);
      }
    };
    fetchData();
  }, [dispatch, id]);

  // Handle Tab Change
  const handleTabChange = (lang) => setActiveTab(lang);
  // Handle Input Changes for each language
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewgrains((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  // Handle Image File Change
  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const modifiedFileName = file.name.replace(/\s+/g, '_');
      const modifiedFile = new File([file], modifiedFileName, { type: file.type });
      setImageData(modifiedFile);
    }
  };

  // Update Data
  const handleUpdateFirstAid = async () => {
    const updatedEntry = {
      ...newgrains,
      grainId: id, // Use the existing ID
    };    

    const formData = new FormData();
    if (imageData instanceof File) {
      formData.append('img', imageData); // Append new file if updated
    }
    formData.append('data', JSON.stringify(updatedEntry));

    try {
      const response = await dispatch(updateGrainsdetails(formData));
      if (response.payload.message === 'Data updated successfully') {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Grains details have been updated successfully.',
        });
        navigate("/grains")
      }
    } catch (error) {
      console.error('Failed to update grains entry:', error);
    }
  };

  const isFormValid = newgrains[activeTab].name && newgrains[activeTab].content && imageData;

  return (
    <div className='icon_backButton'>
      <Link to="/grains">
        <i className="fas fa-arrow-alt-circle-left"></i>
      </Link>
      <div className="containers">
        <h2>Edit grains Details</h2>
        <div className="tabers">
          {languages.map((lang) => (
            <button
              key={lang}
              onClick={() => handleTabChange(lang)}
              className="tabers"
              style={{
                backgroundColor: activeTab === lang ? '#4CAF50' : '#ccc',
              }}
            >
              {lang}
            </button>
          ))}
        </div>
        <div className="commonSection">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="fileInputs"
          />
          {imageData && (
            <img
              src={imageData instanceof File ? URL.createObjectURL(imageData) : imageData}
              alt="Preview"
              className="imagePreview"
            />
          )}
        </div>
        <div className="formContainers">
          <h3>{activeTab} Details</h3>
          <input
            type="text"
            name="name"
            className="inputs"
            value={newgrains[activeTab]?.name || ''}
            onChange={handleInputChange}
            placeholder="Enter Grains Aid Name"
          />
          <textarea
            name="content"
            className="textareas"
            value={newgrains[activeTab]?.content || ''}
            onChange={handleInputChange}
            style={{height:"100px"}}
            placeholder="Enter Grains Aid Content (HTML Format)"
          />
        </div>
        <button
          className="saveButton"
          onClick={handleUpdateFirstAid}
          disabled={!isFormValid}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateFirstAid;
