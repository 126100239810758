import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('Grains/getGrainslist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getgrainslist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const Grainsdetails = createAsyncThunk('Grains/Grainsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/grainsdetails', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addGrainslist = createAsyncThunk('Grains/addGrainslist', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/addgrainslist', {
            method: 'POST',
            body: formData, 
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateGrainsdetails = createAsyncThunk('Grains/updateGrainsdetails', async (formData) => {    
    try {
        const response = await fetch(webservice + 'api/updategrainsdetails', {
            method: 'POST',
            body: formData, 
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteGrainsdetails = createAsyncThunk('Grains/deleteGrainsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deletegrainslist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});