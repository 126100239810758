import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addVaccine } from './store/index';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const languages = ['English', 'Tamil', 'Hindi', 'Telugu', 'Malayalam', 'Kannada'];

const AddVaccinelist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('English');
  const [newVaccine, setNewVaccine] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = { name: '', content: '',reason: '', daysStart: '', daysEnd: '' };
      return acc;
    }, {})
  );
  // Handle Tab Change
  const handleTabChange = (lang) => setActiveTab(lang);

  // Handle Input Changes for each language
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVaccine((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  // Save Data
  const handleAddVaccinelist = async () => {
        const completeCheckupData = languages.reduce((acc, lang) => {
          acc[lang] = newVaccine[lang] || { name: '', content: '',reason: '', daysStart: '', daysEnd: '' };
          return acc;
        }, {});
    
    const data = {
      ...completeCheckupData,
      vaccineId: uuidv4(),
    };

    try {
      // Dispatch the form data directly without wrapping it in another object
      await dispatch(addVaccine(data)).then((response) => {
        if (response.payload.message === 'Data stored successfully') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Vaccine details have been added successfully.'
          });
          navigate("/vaccination")
        }
        // Reset the state after submission
        setNewVaccine(
          languages.reduce((acc, lang) => {
            acc[lang] = { name: '', content: '',reason: '', daysStart: '', daysEnd: '' };
            return acc;
          }, {})
        );
      })
    } catch (error) {
      console.error('Failed to add first aid entry:', error);
    }
  };

  const isFormValid = newVaccine[activeTab].name && newVaccine[activeTab].content && newVaccine[activeTab].reason

  return (
    <div className='icon_backButton'>
      <Link to={"/vaccination"}>
        <i className="fas fa-arrow-alt-circle-left"></i>
      </Link>
      <div className="containers">
        <h2>Add Vaccine List</h2>
        <div className="tabers">
          {languages.map((lang) => (
            <button
              key={lang}
              onClick={() => handleTabChange(lang)}
              className="tabers"
              style={{
                backgroundColor: activeTab === lang ? '#4CAF50' : '#ccc',
              }}
            >
              {lang}
            </button>
          ))}
        </div>
        <div className="formContainers">
          <h3>{activeTab} Details</h3>
          <input
            type="text"
            name="name"
            className="inputs"
            value={newVaccine[activeTab]?.name || ''}
            onChange={handleInputChange}
            placeholder="Enter Vaccine Name"
          />
          <textarea
            name="content"
            className="textareas"
            value={newVaccine[activeTab]?.content || ''}
            style={{height:"100px"}}
            onChange={handleInputChange}
            placeholder="Enter Vaccine Content (HTML Format)"
          />
          <input
            type="text"
            name="reason"
            className="inputs"
            value={newVaccine[activeTab]?.reason || ''}
            onChange={handleInputChange}
            placeholder="Enter Vaccine reason"
          />
          <input
            type="text"
            name="daysStart"
            className="inputs"
            value={newVaccine[activeTab]?.daysStart || ''}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleInputChange(e);
              }
            }}
            placeholder="Enter Days Start"
          />
          <input
            type="text"
            name="daysEnd"
            className="inputs"
            value={newVaccine[activeTab]?.daysEnd || ''}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleInputChange(e);
              }
            }}
            placeholder="Enter Days End"
          />
        </div>
        <button
          className="saveButton"
          onClick={handleAddVaccinelist}
          disabled={!isFormValid}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddVaccinelist;
