import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('Symptoms/getSymptomslist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getsymptomslist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const Symptomsdetails = createAsyncThunk('Symptoms/Symptomsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/symptomsdetails', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addSymptomslist = createAsyncThunk('Symptoms/addSymptomslist', async (data) => {
    try {
        const response = await fetch(webservice + 'api/addsymptoms', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            } 
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateSymptomsdetails = createAsyncThunk('Symptoms/updateSymptomsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/updatesymptoms', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            } 
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteSymptomsdetails = createAsyncThunk('Symptoms/deleteSymptomsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deletesymptoms', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});