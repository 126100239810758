import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('Fruits/getFruitslist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getFruitslist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const Fruitsdetails = createAsyncThunk('Fruits/Fruitsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/Fruitsdetails', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addFruitslist = createAsyncThunk('Fruits/addFruitslist', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/addFruitslist', {
            method: 'POST',
            body: formData, 
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateFruitsdetails = createAsyncThunk('Fruits/updateFruitsdetails', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/updateFruitsdetails', {
            method: 'POST',
            body: formData, 
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteFruitsdetails = createAsyncThunk('Fruits/deleteFruitsdetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteFruitslist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});