import React, { Fragment, useEffect, useState } from "react";
import { getdata, updateApp } from "./store";
import { useDispatch } from "react-redux";
import { Button, Input, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import Switch from "react-switch";

const AppUpdate = () => {
  const dispatch = useDispatch();
  const [appData, setAppData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getdata()).then((response) => {
      setAppData(response.payload.data);
    });
  }, [dispatch]);

  const openModal = (item) => {
    setSelectedData({ ...item }); // Create a new copy to avoid direct state mutation
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    // Update the main app data state
    setAppData((prev) =>
      prev.map((item) => (item.id === selectedData.id ? { ...selectedData } : item))
    );
    // Dispatch the update action
    dispatch(updateApp(selectedData)).then((response) => {
      if(response.payload.message ==="success"){
        Swal.fire({
                title: "Updated Successfully!",
                icon: "success",
                confirmButtonText: "OK",
            })
      }
      dispatch(getdata()).then((response) => {
        setAppData(response.payload.data);
      });
    });
    closeModal();
  };

  const renderMobileIcon = (mobile) => {
    if (mobile === "iOS") {
      return <i className="fab fa-apple" style={{ marginRight: "8px" }}></i>;
    } else if (mobile === "Android") {
      return <i className="fab fa-android" style={{ marginRight: "8px" }}></i>;
    }
    return null;
  };

  return (
    <Fragment>
      <h1>App Update Details</h1>
      <div className="card-container">
        {appData.map((item) => (
          <div key={item.id} className="app-card">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h2 style={{ margin: 0 }}>
                {renderMobileIcon(item.mobile)}
                {item.mobile}
              </h2>
              <Button className="btn-edit" onClick={() => openModal(item)}>
                <i className="fas fa-pen"></i>
              </Button>
            </div>
            <p>
              <strong>Title:</strong> {item.title}
            </p>
            <p>
              <strong>App Version:</strong> {item.appVersion}
            </p>
            <p>
              <strong>Remainder Version:</strong> {item.remainderVersion}
            </p>
            <div style={{ overflowWrap: "anywhere" }}>
              <strong>Enhancement:</strong>
              <br />
              {item.enhancement.map((enh, index) => (
                <p key={index}>{enh}</p>
              ))}
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && selectedData && (
        <Modal isOpen={isModalOpen} toggle={closeModal} size="xl">
          <ModalHeader toggle={closeModal}>
            <h3>Edit App Update Details</h3>
          </ModalHeader>
          <ModalBody>
            <Label>Title:</Label>
            <Input
              type="text"
              name="title"
              value={selectedData?.title || ""}
              onChange={handleChange}
              style={{ color: "black" }} 
            />

            <Label>App Version:</Label>
            <Input
              type="text"
              name="appVersion"
              value={selectedData?.appVersion || ""}
              onChange={handleChange}
              style={{ color: "black" }} 
            />

            <Label>Remainder Version:</Label>
            <Input
              type="text"
              name="remainderVersion"
              value={selectedData?.remainderVersion || ""}
              onChange={handleChange}
              style={{ color: "black" }} 
            />
            <Label>Enhancements:</Label>
            <Input
              type="textarea"
              name="enhancement"
              value={selectedData?.enhancement?.join("\n") || ""}
              onChange={(e) => setSelectedData((prev) => ({
                ...prev,
                enhancement: e.target.value.split("\n"), // Convert back to an array
              }))}
              style={{ color: "black",height:"150px" }} 
            />
            <br />
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Label>Force:</Label>
            <Switch
              checked={selectedData?.force || false}
              onChange={(checked) => {
                setSelectedData((prev) => ({
                  ...prev,
                  force: checked, // Update the force value in selectedData
                }));
              }}
            />
          </div>
            <br/>
            <div className="div-btn">
              <button className="btn-edit" onClick={handleSave}>Save</button>&nbsp;
              <button className="btn-cancel" onClick={closeModal}>Cancel</button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  );
};

export default AppUpdate;
