import React from "react";
import { useDispatch, useSelector } from 'react-redux';
/// React router dom
import { Link } from "react-router-dom";
/// images
import logo from "../../../images/logo.jpg";
// import logoText from "../../../images/logo-text.png";
import { navtoggle } from "../../../store/actions/AuthActions";
import { useState } from "react";

const NavHader = () => {

   const dispatch = useDispatch();
   const [isTitleVisible, setIsTitleVisible] = useState(true);
   const sideMenu = useSelector(state => state.sideMenu);
   const handleToogle = () => {
      dispatch(navtoggle());
   };

   return (
      <>
         <div className="nav-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px' }}>
            <Link
               to="/"
               className="brand-logo"
               style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
               <img
                  className="logo-abbr"
                  src={logo}
                  alt=""
                  style={{ marginLeft: isTitleVisible ? '-10px' : '-30px', marginRight: isTitleVisible ? '0px' : '-10px' }}
               />
               {isTitleVisible && window.innerWidth > 1284 && (
                    <>edHorizone</> 
               )}
            </Link>
         </div >
      </>
   );
};

export default NavHader;
