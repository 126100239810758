import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FaUserAlt } from "react-icons/fa";
import webservice from "./../../../services/webservice";
import { IoIosSend } from "react-icons/io";


const Notification = () => {

    const [activeTab, setActiveTab] = useState("compose");
    const [notifications, setNotifications] = useState([]);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [showCompose, setShowCompose] = useState(false);
    const [allUsersChecked, setAllUsersChecked] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
        ageFilter: false,
        gender: false,
        maritalStatus: false
    });
    const [notificationSent, setNotificationSent] = useState(false);

    const [ageRange, setAgeRange] = useState("");
    const [gender, setGender] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");

    const handleCheckboxChange = (filter) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [filter]: !prevFilters[filter]
        }));
    };

    const handleAllUsersChange = (e) => {
        setAllUsersChecked(e.target.checked);
    };


    useEffect(() => {
        if (notificationSent) {
            Swal.fire({
                icon: "success",
                title: "Notification Sent",
                text: "Your notification has been delivered successfully!",
            });
            setNotificationSent(false); // Reset state after showing alert
        }
    }, [notificationSent]);
    
    const sendNotification = async (e) => {
        e.preventDefault();
    
        const data = {
            title,
            message,
            allUsersChecked,
            ageRange,
            gender,
            maritalStatus
        };
    
        try {
            const response = await fetch(webservice + 'api/sendNotification', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            });
    
            const result = await response.json();
    
            if (result.success) {
                setNotificationSent(true); 
                setTitle("");
                setMessage("");
                setAllUsersChecked(false); // ✅ Reset checkbox to default value
            } else {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: result.message,
                });
            }
        } catch (error) {
            console.error("Error sending notification:", error);
            Swal.fire({
                icon: "error",
                title: "Failed",
                text: "Something went wrong. Please try again!",
            });
        }
    };


    return (
        <>

            <div className='notification_container'>
                <div className='notification_title'>
                    <h4 className='noti_title'>
                        Send notification - <FaUserAlt className='icon' />
                    </h4>
                    <div className='noti_btn'>
                        <div className="tabs-list">
                            <button className={`tab-button ${activeTab === "compose" ? "active" : ""}`}
                                onClick={() => setActiveTab("compose")}>
                                Compose
                            </button>
                            <button className={`tab-button ${activeTab === "history" ? "active" : ""}`}
                                onClick={() => setActiveTab("history")}>
                                History
                            </button>

                        </div>
                    </div>
                </div>


                <>

                    <div className="tabs-container">


                        {activeTab === "compose" && (
                            <div className="noti-card">
                                <div className="noti-card-header">
                                    <h3>Compose Notification</h3>
                                    <p>Create and send targeted notifications to your users</p>
                                </div>

                                {!showCompose ? (
                                    <button
                                        className="compose-btn"
                                        style={{ fontSize: "16px", padding: "10px 20px", marginBottom: "10px" }}
                                        onClick={() => setShowCompose(true)}
                                    >
                                        Compose Notification
                                    </button>
                                ) : (
                                    <>

                                        <form className="card-content" onSubmit={sendNotification}>
                                            <div className='noti-groups'>
                                                <label>Notification Title</label>
                                                <input
                                                    type="text"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    placeholder="Enter title"
                                                    className="notification-input"
                                                    required
                                                />
                                            </div>

                                            <div className='noti-groups'>
                                                <label>Message</label>
                                                <textarea
                                                    rows={2}
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder="Type your message here"
                                                    className="notification-input"
                                                    style={{ padding: "4px 10px" }}
                                                    required
                                                ></textarea>
                                            </div>

                                            <div className='noti-groups'>
                                                <label>Recipients</label>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                                                    {/* "All Users" Checkbox */}
                                                    <div className='checkbox_label notification-input' style={{ border: "1px solid #eee" }}>
                                                        <input
                                                            type="checkbox"
                                                            className="custom-checkbox"
                                                            checked={allUsersChecked}
                                                            onChange={handleAllUsersChange}
                                                        />
                                                        <label style={{ fontSize: "16px", marginBottom: "0rem", marginLeft: "4px" }}>All Users</label>
                                                    </div>

                                                    {/* Other Checkboxes - Hidden if "All Users" is checked */}
                                                    {!allUsersChecked && (
                                                        <>
                                                            {[
                                                                { label: "Age Filter", key: "ageFilter" },
                                                                { label: "Gender", key: "gender" },
                                                                { label: "Marital Status", key: "maritalStatus" }
                                                            ].map(({ label, key }) => (
                                                                <div key={key} className="checkbox_label notification-input" style={{ border: "1px solid #eee" }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-checkbox"
                                                                        checked={selectedFilters[key]}
                                                                        onChange={() => handleCheckboxChange(key)}
                                                                    />
                                                                    <label style={{ fontSize: "16px", marginBottom: "0rem", marginLeft: "4px" }}>{label}</label>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            {!allUsersChecked && (
                                                <div className="row-select-inputs">
                                                    {selectedFilters.ageFilter && (
                                                        <div className="select-inputs">
                                                            <label>Age Range</label>
                                                            <select
                                                                className="notification-input"
                                                                value={ageRange}
                                                                onChange={(e) => setAgeRange(e.target.value)}
                                                                required={selectedFilters.ageFilter}
                                                            >
                                                                <option value="">Select age range</option>
                                                                <option value="12-17">12-17</option>
                                                                <option value="18-24">18-24</option>
                                                                <option value="25-34">25-34</option>
                                                                <option value="35-44">35-44</option>
                                                                <option value="45+">45+</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                    {selectedFilters.gender && (
                                                        <div className="select-inputs">
                                                            <label>Gender</label>
                                                            <select
                                                                className="notification-input"
                                                                value={gender}
                                                                onChange={(e) => setGender(e.target.value)}
                                                                required={selectedFilters.gender}
                                                            >
                                                                <option value="">Select gender</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                    {selectedFilters.maritalStatus && (
                                                        <div className="select-inputs">
                                                            <label>Marital Status</label>
                                                            <select
                                                                className="notification-input"
                                                                value={maritalStatus}
                                                                onChange={(e) => setMaritalStatus(e.target.value)}
                                                                required={selectedFilters.maritalStatus}
                                                            >
                                                                <option value="">Select marital status</option>
                                                                <option value="Single">Single</option>
                                                                <option value="Married">Married</option>
                                                                <option value="Divorced">Divorced</option>
                                                                <option value="Widowed">Widowed</option>
                                                            </select>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                                                <button
                                                    type="submit"
                                                    className="send-notification-btn"
                                                    style={{ fontSize: "16px", display: "flex", alignItems: "center", gap: "10px" }}
                                                >
                                                    <IoIosSend style={{ fontSize: "20px" }} />Send Notification
                                                </button>
                                            </div>
                                        </form>


                                    </>
                                )}
                            </div>
                        )}

                        {activeTab === "history" && (
                            <div className="noti-card">
                                <div className="noti-card-header" >

                                    <h3>Notification History</h3>
                                    <p>Review your sent notifications</p>
                                </div>

                                <div className="noti-card-content">
                                    {notifications.length > 0 ? (
                                        <ul>
                                            {notifications.map((note, index) => (
                                                <li key={index}>
                                                    <strong>{note.title}</strong>: {note.message}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No notifications sent yet.</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>


                </>


            </div>

        </>

    )
}

export default Notification