import React, { useEffect, useState } from 'react'
import FilterBar from '../Filter/FilterBar'
import { ImAndroid } from "react-icons/im";
import { FaAppStoreIos } from "react-icons/fa";
import { FaChrome } from "react-icons/fa";
import { HiScale } from "react-icons/hi";
import { TbTriangleSquareCircleFilled } from "react-icons/tb";
import { MdBloodtype } from "react-icons/md";
import { IoIosBookmarks } from "react-icons/io";
import { TbMoodSmileFilled } from "react-icons/tb";
import { IoIosDocument } from "react-icons/io";
import { LuMicroscope } from "react-icons/lu";
import { FaStethoscope } from "react-icons/fa";
import { useForm } from 'react-hook-form';
// import {getuserdata ,blooddonate,Moodmonitor,documentuploades,Bloodpressure,  bloodsugar } from "./store";
import { getuserdata, fetchDashboardData } from "./store";
import { useDispatch } from 'react-redux';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";





const Home = () => {
  const dispatch = useDispatch();

  const [totaliOS, setTotaliOS] = useState(0);
  const [totalAndroid, setTotalAndroid] = useState(0);
  const [totalWeb, setTotalWeb] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [bloodDonateTotal, setBloodDonateTotal] = useState(0);
  const [moodMonitorTotal, setMoodMonitorTotal] = useState(0);
  const [documentUploadsTotal, setDocumentUploadsTotal] = useState(0);
  const [bloodPressureTotal, setBloodPressureTotal] = useState(0);
  const [bloodSugarTotal, setBloodSugarTotal] = useState(0);

  const localname = localStorage.name

  const {
    control,
    setValue,
    formState: { },
    reset,
  } = useForm();


  const [dates, setDates] = useState([]); 
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getuserdata())
      .then((response) => {
        const TempData = response.payload;

        // ✅ Extract data safely
        const deviceStats = TempData.deviceStatistics || {};
        const res_date = TempData.createdDates || []; // ✅ Ensure it's an array

        // ✅ Get total users
        const totaliOS = deviceStats.iOS || 0;
        const totalAndroid = deviceStats.Android || 0;
        const totalWeb = deviceStats.Web || 0;
        const total = totaliOS + totalAndroid + totalWeb;

        // ✅ Set states
        setTotaliOS(totaliOS);
        setTotalAndroid(totalAndroid)
        setTotalWeb(totalWeb)
        setTotalUsers(total);
        setDates(res_date);

        // console.log("Total Users:", total);
        // console.log("Device Breakdown:", { iOS: totaliOS, Android: totalAndroid, Web: totalWeb });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);



  useEffect(() => {
    if (!Array.isArray(dates) || dates.length === 0) return;
  
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    const monthActivityMap = {};
  
    dates.forEach(date => {
      const monthIndex = new Date(date).getMonth();
      const monthName = monthNames[monthIndex];
  
      monthActivityMap[monthName] = (monthActivityMap[monthName] || 0) + 1;
    });
  
    const chartData = monthNames.map(month => ({
      name: month,
      activity: monthActivityMap[month] || 0, // Default 0 for missing months
    }));
  
    setData(chartData);
  }, [dates]);


  //   useEffect(() => {
  //     dispatch(blooddonate())
  //         .then((response) => {
  //             const TempData = response.payload;

  //             // Directly access the array from TempData
  //             const userArray = TempData.data || []; // Safeguard with || []
  //             console.log(userArray);

  //             setblooddonatetotal(userArray.length); // Set the length of the array
  //         })
  //         .catch((error) => {
  //             console.error('Error fetching user data:', error);
  //         });
  // }, []);
  //   useEffect(() => {
  //     dispatch(Moodmonitor())
  //         .then((response) => {
  //             const TempData = response.payload;

  //             // Directly access the array from TempData
  //             const userArray = TempData.data || []; // Safeguard with || []
  //             console.log(userArray);

  //             setMoodmonitortotal(userArray.length); // Set the length of the array
  //         })
  //         .catch((error) => {
  //             console.error('Error fetching user data:', error);
  //         });
  // }, []);
  //   useEffect(() => {
  //     dispatch(documentuploades())
  //         .then((response) => {
  //             const TempData = response.payload;

  //             // Directly access the array from TempData
  //             const userArray = TempData.data || []; // Safeguard with || []
  //             console.log(userArray);

  //             setdocumentuploadestotal(userArray.length); // Set the length of the array
  //         })
  //         .catch((error) => {
  //             console.error('Error fetching user data:', error);
  //         });
  // }, []);
  //   useEffect(() => {
  //     dispatch(Bloodpressure())
  //         .then((response) => {
  //             const TempData = response.payload;

  //             // Directly access the array from TempData
  //             const userArray = TempData.data || []; // Safeguard with || []
  //             console.log(userArray);

  //             setBloodpressurestotal(userArray.length); // Set the length of the array
  //         })
  //         .catch((error) => {
  //             console.error('Error fetching user data:', error);
  //         });
  // }, []);
  //   useEffect(() => {
  //     dispatch(bloodsugar())
  //         .then((response) => {
  //             const TempData = response.payload;

  //             // Directly access the array from TempData
  //             const userArray = TempData.data || []; // Safeguard with || []
  //             console.log(userArray);

  //             setbloodsugartotal(userArray.length); // Set the length of the array
  //         })
  //         .catch((error) => {
  //             console.error('Error fetching user data:', error);
  //         });
  // }, []);


  useEffect(() => {
    dispatch(fetchDashboardData())
      .then((response) => {
        const data = response.payload;

        // Update totals for each card
        setBloodDonateTotal(data.bloodDonate.length);
        setMoodMonitorTotal(data.moodMonitor.length);
        setDocumentUploadsTotal(data.documentUploads.length);
        setBloodPressureTotal(data.bloodPressure.length);
        setBloodSugarTotal(data.bloodSugar.length);
      })
      .catch((error) => {
        console.error('Error fetching dashboard data:', error);
      });
  }, [dispatch]);


  const [term, setTerm] = useState(""); // User input
  const [icdCodes, setICDCodes] = useState([]); // List of ICD codes
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [medlineData, setMedlineData] = useState(null); // MedlinePlus data

  // Fetch ICD-10 codes based on user input
  const fetchICDCodes = async (searchTerm) => {
    setLoading(true);
    setError(null);
    setICDCodes([]);
    setMedlineData(null);

    try {
      // Example API for ICD-10 codes (Replace with a valid API)
      const response = await fetch(
        `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${searchTerm}`
      );
      if (!response.ok) {
        throw new Error("Error fetching ICD-10 codes.");
      }
      const result = await response.json();

      // Extract matching ICD-10 codes and descriptions
      const codes = result[3].map((item, index) => ({
        code: item[0], // ICD code
        description: item[1], // Description
      }));
      setICDCodes(codes);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch MedlinePlus data for an ICD-10 code
  const fetchMedlinePlusData = async (code) => {
    setLoading(true);
    setError(null);
    setMedlineData(null);

    try {
      const response = await fetch(
        `https://connect.medlineplus.gov/service?mainSearchCriteria.v.c=${code}&mainSearchCriteria.v.cs=2.16.840.1.113883.6.90&knowledgeResponseType=application/json`
      );
      if (!response.ok) {
        throw new Error("Error fetching MedlinePlus data.");
      }
      const result = await response.json();

      setMedlineData(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle search when the user submits a term
  const handleSearch = async () => {
    if (!term) return;
    await fetchICDCodes(term); // Fetch matching ICD-10 codes
  };

  return (
    <>


    <div style={{marginTop:"-60px"}}>


      {/* -------------- allusers -------------- */}
      {/* <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Search for Medical Information</h1>
      <div style={{ marginBottom: "10px" }}>
        <input
          type="text"
          placeholder="Enter a health term (e.g., headache, diabetes)..."
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          style={{
            padding: "10px",
            width: "300px",
            fontSize: "16px",
            marginRight: "10px",
          }}
        />
        <button
          onClick={handleSearch}
          style={{
            padding: "10px",
            fontSize: "16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          Search
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

     
      {icdCodes.length > 0 && (
        <div>
          <h2>Matching ICD-10 Codes</h2>
          <ul>
            {icdCodes.map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => fetchMedlinePlusData(item.code)}
                  style={{
                    border: "none",
                    background: "none",
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {item.code}: {item.description}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

     
      {medlineData && medlineData.feed && medlineData.feed.entry && (
        <div>
          <h2>Medical Information</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: medlineData.feed.entry[0].summary._value,
            }}
          />
        </div>
      )}
    </div> */}

      {/* <div className='home_dashboard'>
        <div className='design_1'></div>
        <div className='design_2'></div>
        <div className='design_21'></div>
        <div className='design_3'></div>
        <div className='design_4'></div>
        <div className='design_5'></div>
        <div className='design_6'></div>
        <div className="dash_header">
          <div className="greeting">Good Morning</div>
          <div className="user_name">{localname}</div>
          <div className="dashboard_message">Your Dashboard</div>
        </div>


      </div> */}

      {/* -------------- allusers -------------- */}
      <div className="alluser">
        <div className="devices">

          {/* iPhone */}
          <div className='wrap_container'>
            <div className="iphone">
              <div className="icon_out_circle">
                <div className="icon_inner_circle">
                  <div className='icon_size'>
                    <FaAppStoreIos className='icon' />
                  </div>
                </div>
              </div>
              <div className='usage'>
                <p className='usage_numbers'>{totaliOS}</p>
                <strong className='usage_text'>IOS Users</strong>
              </div>
            </div>

            {/* Android */}
            <div className="Android">
              <div className="icon_out_circle">
                <div className="icon_inner_circle">
                  <div className='icon_size'>
                    <ImAndroid className="icon" />
                  </div>
                </div>
              </div>

              <div className='usage'>
                <p className='usage_numbers'>{totalAndroid}</p>
                <strong className='usage_text'>Android Users</strong>
              </div>
            </div>
          </div>

          {/* Website */}
          <div className='wrap_container'>
            <div className="website">
              <div className="icon_out_circle">
                <div className="icon_inner_circle">
                  <div className='icon_size'>
                    <FaChrome className='icon' />
                  </div>
                </div>
              </div>
              <div className='usage'>
                <p className='usage_numbers'>{totalWeb}</p>
                <strong className='usage_text'>Website Users</strong>
              </div>
            </div>

            {/* Total */}
            <div className="total">
              <div className="icon_out_circle">
                <div className="icon_inner_circle">
                  <div className='icon_size'>
                    <TbTriangleSquareCircleFilled className='icon' />
                  </div>
                </div>
              </div>
              <div className='usage'>
                <p className='usage_numbers'>{totalUsers}</p>
                <strong className='usage_text'>Total Users</strong>
              </div>
            </div>
          </div>
        </div>


      </div>
      {/* -------------- allusers -------------- */}

      <div className='users_action' >
        <div className='useraction_wrapper'>
          <div className='userAct Appoinment' >
            <div className='userAct_container'>
              <h5>Appoinments</h5>
              <div className='icon_text'>
                <div className="userAct_outer_circle">
                  <div className="userAct_inner_circle">
                    <div className='users_icon_size'>
                      <IoIosBookmarks className='Appoinment' />
                    </div>
                  </div>
                </div>
                <div className='usernumber'>
                  <div className='usersAct_numbers'>600k books</div>

                </div>
              </div>

            </div>
          </div>
          {/* blooddonation */}
          <div className='userAct blood_donation'>
            <div className='userAct_container'>
              <h5>blood donation</h5>
              <div className='icon_text'>
                <div className="userAct_outer_circle">
                  <div className="userAct_inner_circle">
                    <div className='users_icon_size'>
                      <MdBloodtype className='blooddonation' />
                    </div>
                  </div>
                </div>
                <div className='usernumber'>
                  <div className='usersAct_numbers'>{bloodDonateTotal} donates</div>

                </div>
              </div>

            </div>
          </div>
        </div>
        {/* blooddonation */}
        <div className='useraction_wrapper'>
          <div className='userAct mood_monitor'>
            <div className='userAct_container'>
              <h5>Mood Monitor</h5>
              <div className='icon_text'>
                <div className="userAct_outer_circle">
                  <div className="userAct_inner_circle">
                    <div className='users_icon_size'>
                      <TbMoodSmileFilled className='mood_monitor' />
                    </div>
                  </div>
                </div>
                <div className='usernumber'>
                  <div className='usersAct_numbers'>{moodMonitorTotal} entered</div>

                </div>
              </div>

            </div>
          </div>
          {/* blooddonation */}
          <div className='userAct Document'>
            <div className='userAct_container'>
              <h5>document upload</h5>
              <div className='icon_text'>
                <div className="userAct_outer_circle">
                  <div className="userAct_inner_circle">
                    <div className='users_icon_size'>
                      <IoIosDocument className='Document' />
                    </div>
                  </div>
                </div>
                <div className='usernumber'>
                  <div className='usersAct_numbers'>{documentUploadsTotal} uploads</div>

                </div>
              </div>

            </div>
          </div>
        </div>
        {/* blooddonation */}

        <div className='useraction_wrapper'>
          <div className='userAct blood_sugar'>
            <div className='userAct_container'>
              <h5>blood Pressure</h5>
              <div className='icon_text'>
                <div className="userAct_outer_circle">
                  <div className="userAct_inner_circle">
                    <div className='users_icon_size'>
                      <FaStethoscope className='bloodpresure' />

                    </div>
                  </div>
                </div>
                <div className='usernumber'>
                  <div className='usersAct_numbers'>{bloodPressureTotal} records</div>

                </div>
              </div>

            </div>
          </div>
          {/* blooddonation */}
          <div className='userAct bloodpresure'>
            <div className='userAct_container'>
              <h5>blood Sugar</h5>

              <div className='icon_text'>
                <div className="userAct_outer_circle">
                  <div className="userAct_inner_circle">
                    <div className='users_icon_size'>
                      <LuMicroscope className='blood_sugar' />
                    </div>
                  </div>
                </div>
                <div className='usernumber'>
                  <div className='usersAct_numbers'>{bloodSugarTotal} records</div>

                </div>
              </div>

            </div>
          </div>
        </div>




      </div>

      {/* user charts and location */}
      <div className='user_charts_locations'>
       <div className="user_charts_container">
       
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: 10 }} />
          <YAxis tick={{ fontSize: 10 }} />
          <Tooltip />
          <Line type="monotone" dataKey="activity" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
        <div className='user_location_container'>
          <h3>User location</h3>
          <div className='state_con'>
            <div className='state'>
              <h4>Tamil Nadu  <div>4 users</div></h4>

            </div>
            <div className='state'>
              <h4>kerala  <div>4 users</div></h4>

            </div>
            <div className='state'>
              <h4>vellore  <div>4 users</div></h4>

            </div>
            <div className='state'>
              <h4>delhi  <div>4 users</div></h4>

            </div>
            <div className='state'>
              <h4>Mumbai  <div>4 users</div></h4>

            </div>







          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Home