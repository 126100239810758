import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import webservice from '../../../../services/webservice'
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';


// export const getbloodData = createAsyncThunk('dashboard/getbloodData', async (data) => {
//     const Encparams = AesEncrypt(data);
//     try {
//         const response = await fetch(webservice + 'api/getbloodData', {

//             method: 'POST',
//             body: JSON.stringify({ Encparams }), // Pass the entire data object directly
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// })
// export const getbloodsugarlevel = createAsyncThunk('dashboard/getbloodsugarlevel', async (data) => {
//     const Encparams = AesEncrypt(data);
//     try {
//         const response = await fetch(webservice + 'api/getBloodsugarLevel', {

//             method: 'POST',
//             body: JSON.stringify({ Encparams }), // Pass the entire data object directly
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// })
// export const getmooddata = createAsyncThunk('dashboard/getmooddata', async (data) => {
//     const Encparams = AesEncrypt(data);
//     try {
//         const response = await fetch(webservice + 'api/getMoodData', {

//             method: 'POST',
//             body: JSON.stringify({ Encparams: Encparams }), // Pass the entire data object directly
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// })


// export const getBmi = createAsyncThunk('dashboard/getBmi', async (data) => {
//     const Encparams = AesEncrypt(data);
//     try {
//         const response = await fetch(webservice + 'api/getBmi', {

//             method: 'POST',
//             body: JSON.stringify({ Encparams }), // Pass the entire data object directly
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });
//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// })


export const getbloodsugarchart = createAsyncThunk('dashboard/getbloodsugarchart', async (data) => {
    const Encparams = AesEncrypt(data);
    try {
        const response = await fetch(webservice + 'api/getBloodsugarChart', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
}
);

export const getbloodpresurechartData = createAsyncThunk('dashboard/getbloodpresurechartData', async (data) => {
    const Encparams = AesEncrypt(data);
    try {
        const response = await fetch(webservice + 'api/getBloodpresureChart', {

            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
}
);
export const getstepcount = createAsyncThunk('dashboard/getstepcount', async (data) => {
    // Corrected variable name from Idd to userId
    const requestData = {
        userId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getStepCount ', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const getstep = createAsyncThunk('dashboard/getstepcount', async (data) => {
    // Corrected variable name from Idd to userId
    const requestData = {
        userId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getStep ', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const getstepChart = createAsyncThunk('dashboard/getstepcount', async (data) => {
    // Corrected variable name from Idd to userId
    const requestData = {
        userId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getstepChart ', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

// Add Step
export const Setstep = createAsyncThunk('profile/Addstep', async (data) => {
    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/addStep', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const familyData = await response.json();
    return familyData;
})

export const getuserdata = createAsyncThunk('Dashboard/getuserdata', async () => {
    const response = await fetch(webservice + 'api/getuserdata', {
         method: 'GET',
         headers: {
              'Content-type': 'application/json; charset=UTF-8',
         },
    })
         .then((response) => response.json())
    return response;
})



// export const blooddonate = createAsyncThunk('Dashboard/blooddonate', async () => {
//     const response = await fetch(webservice + 'api/blooddonate', {
//          method: 'GET',
//          headers: {
//               'Content-type': 'application/json; charset=UTF-8',
//          },
//     })
//          .then((response) => response.json())
//     return response;
// })
// export const Moodmonitor = createAsyncThunk('Dashboard/Moodmonitor', async () => {
//     const response = await fetch(webservice + 'api/Moodmonitor', {
//          method: 'GET',
//          headers: {
//               'Content-type': 'application/json; charset=UTF-8',
//          },
//     })
//          .then((response) => response.json())
//     return response;
// })
// export const documentuploades = createAsyncThunk('Dashboard/documentuploades', async () => {
//     const response = await fetch(webservice + 'api/documentuploades', {
//          method: 'GET',
//          headers: {
//               'Content-type': 'application/json; charset=UTF-8',
//          },
//     })
//          .then((response) => response.json())
//     return response;
// })
// export const Bloodpressure = createAsyncThunk('Dashboard/Bloodpressure', async () => {
//     const response = await fetch(webservice + 'api/Bloodpressure', {
//          method: 'GET',
//          headers: {
//               'Content-type': 'application/json; charset=UTF-8',
//          },
//     })
//          .then((response) => response.json())
//     return response;
// })
// export const bloodsugar = createAsyncThunk('Dashboard/bloodsugar', async () => {
//     const response = await fetch(webservice + 'api/bloodsugar', {
//          method: 'GET',
//          headers: {
//               'Content-type': 'application/json; charset=UTF-8',
//          },
//     })
//          .then((response) => response.json())
//     return response;
// })



export const fetchDashboardData = createAsyncThunk('Dashboard/fetchDashboardData', async () => {
    const response = await fetch(webservice + 'api/dashboard-data', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    return response.json();
  });