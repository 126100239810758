import webservice from '../../../../services/webservice';
import {createAsyncThunk } from '@reduxjs/toolkit'

export const getdata = createAsyncThunk('FirstAid/getFirstAidlist', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/getFirstAidlist', {
            body: JSON.stringify({ lang }),
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const FirstAiddetails = createAsyncThunk('FirstAid/FirstAiddetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/FirstAiddetails', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const addFirstAidlist = createAsyncThunk('FirstAid/addFirstAidlist', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/addFirstAidlist', {
            method: 'POST',
            body: formData, 
        });

        const responseData = await response.json()
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
});

export const updateFirstAiddetails = createAsyncThunk('FirstAid/updateFirstAiddetails', async (formData) => {
    try {
        const response = await fetch(webservice + 'api/updateFirstAiddetails', {
            method: 'POST',
            body: formData, 
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});

export const deleteFirstAiddetails = createAsyncThunk('FirstAid/deleteFirstAiddetails', async (data) => {
    try {
        const response = await fetch(webservice + 'api/deleteFirstAidlist', {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; 
    }
});