import React, { useState, useEffect, Fragment } from "react";
import { getdata, MedicalcheckupList, deleteCheckupdetails } from "./store/index";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'
import Breadcrumbs from '../../breadcrumbs'
import img from '../../../images/no-data.svg'
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";

const Medicalcheckup = () => {
  const [allData, setAllData] = useState([]);
  const [viewAllData, setViewAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');
  const toggleModal = () => setModalOpen(!modalOpen);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    const response = await dispatch(getdata(lang));
    if (response.payload && response.payload.data) {
      setAllData(response.payload.data);
      setFilteredData(response.payload.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (lang !== '') {
      fetchData();
    }
  }, [dispatch, lang]);


  const handleView = (id) => {
    dispatch(MedicalcheckupList(id)).then((res) => {
      setViewAllData(res.payload.data)
      setModalOpen(true);
    })
  }

  const handleEdit = (id) => {
    navigate(`/med_list/edit/${id}`)
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Medical check Up Details',
      text: 'Are you sure want to delete this record?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: t('OK'),
      cancelButtonText: t('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCheckupdetails(id)).then((res) => {
          if (res.payload.response === 'S') {
            Swal.fire({
              icon: 'success',
              title: t('Success!'),
              text: t('Deleted Successfully'),
            });
            fetchData(); // Call fetchData after deletion to update the list
          }
        });
      }
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    const filtered = allData.filter(item => item.name.toLowerCase().includes(value));
    setFilteredData(filtered);
  };


  return (
    <Fragment>
      <Breadcrumbs title={t('Medical Checkup Details')} data={[{ title: t('Medical Checkup') }]} />
      <div className="d-flex justify-content-end mb-3">
        <Input
          type="text"
          placeholder={t("Search by name...")}
          value={searchQuery}
          onChange={handleSearch}
          className="w-50"
        />
        <i
          className="flaticon-381-add-3 text-primary mx-4 add-icon"
          onClick={() => navigate('/med_list/add')}
          style={{ cursor: 'pointer' }}
        ></i>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : filteredData.length === 0 ? (
        <div className="d-flex justify-content-center">
          <img src={img} alt="No Data" style={{ height: '50vh' }} />
        </div>
      ) : (
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('No')}</th>
                <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Name')}</th>
                <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Date')}</th>
                <th style={{ backgroundColor: 'white', textAlign: 'center' }}>{t('Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ textAlign: 'center' }}>{item.name}</td>
                  <td style={{ textAlign: 'center' }}>{item.lastSyncDate}</td>
                  <td>
                    <div style={{ justifyContent: 'space-around' }} className="d-flex">
                      <Link to={`/med_list`} onClick={() => handleView(item.CheckupId)}>
                        <svg
                          style={{ color: 'green' }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-eye"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      </Link>
                      <Button
                        color="primary"
                        className="shadow btn-xs sharp me-1"
                        onClick={() => handleEdit(item.CheckupId)}
                      >
                        <i className="fas fa-pen"></i>
                      </Button>
                      <Button
                        color="danger"
                        className="shadow btn-xs sharp"
                        onClick={() => handleDelete(item.CheckupId)}
                      >
                        <i className="fa fa-trash"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* view Details */}
      <Modal
        isOpen={modalOpen} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>{t("First Aid Details")}</ModalHeader>
        <ModalBody>
          <Tabs
            selectedIndex={selectedTabIndex}
            onSelect={(index) => setSelectedTabIndex(index)}
          >
            <TabList >
              {viewAllData.map((item, index) => (
                <Tab key={index}>{item.collectionName.split("_")[1].charAt(0).toUpperCase() + item.collectionName.split("_")[1].slice(1)}</Tab>

              ))}
            </TabList>
            {viewAllData.map((item, index) => (
              <TabPanel key={index}>
                <br />
                <h2>{item.data.name}</h2>
                <br />
                <div className="col">
                  <div className="text-black">
                    <div dangerouslySetInnerHTML={{ __html: item.data.content }}></div>
                  </div>
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Medicalcheckup;
